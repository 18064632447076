import * as moment from 'moment';
import 'moment/locale/ru';

const data = window.data;
let isFirstTimeChecked;

const capitalize = (s) => {
  return s[0].toUpperCase() + s.slice(1);
};

const createDateArray = (startDate, endDate) => {
  const startDayMoment = moment(startDate, 'YYYY-MM-DD');
  const endDayMoment = moment(endDate, 'YYYY-MM-DD');
  const diff = endDayMoment.diff(startDayMoment, 'days');
  const dateArray = [];

  for (let i = 0; i < diff; i++) {
    const date = startDayMoment.add((1), 'day');

    dateArray.push(date.format('YYYY-MM-DD'));
  }

  return dateArray;
};

const initRegistration = () => {
  const registrationCalendarBlock = document.querySelector('.registration-calendar');

  if (!registrationCalendarBlock) {
    return;
  }

  const startDate = registrationCalendarBlock.dataset.dateStart;
  const endDate = registrationCalendarBlock.dataset.dateEnd;

  const dateArray = createDateArray(startDate, endDate);

  // array from data-start to data-end
  const sliderWrapper = document.querySelector('.registration-calendar__wrap');
  const slidesNumber = Math.ceil(dateArray.length / 5);
  const slideTemplate = document.querySelector('#calendar-slide');
  const dayTemplate = document.querySelector('#calendar-day');
  const timeTemplate = document.querySelector('#calendar-time');
  const times = data.times;
  moment.locale('ru');

  sliderWrapper.innerHTML = '';

  const createTimeNode = (time, isTimeEnable, day) => {
    // each time in day
    const timeNode = timeTemplate.content.cloneNode(true);
    // check time disable
    timeNode.querySelector('.registration-calendar__time-input').value = moment(day).format('YYYY-MM-DD') + ' ' + time;
    timeNode.querySelector('.registration-calendar__time-input').disabled = !isTimeEnable;
    timeNode.querySelector('.registration-calendar__time-text').textContent = time;

    if (isTimeEnable && !isFirstTimeChecked) {
      timeNode.querySelector('.registration-calendar__time-input').setAttribute('checked', '');
      isFirstTimeChecked = true;
    }

    return timeNode;
  };

  const createDayNode = (day) => {
    const dayNode = dayTemplate.content.cloneNode(true);
    const dayCol = dayNode.querySelector('.registration-calendar__day-col');
    const dayText = dayNode.querySelector('.registration-calendar__day');
    dayText.textContent = `${capitalize(moment(day).format('dd'))} ${moment(day).format('D')}`;
    const dayData = data.days.find((obj) => obj.day === day);


    times.forEach((time) => {
      let isTimeEnable = false;

      if (dayData) {
        isTimeEnable = dayData.times.includes(time);
      }

      const timeNode = createTimeNode(time, isTimeEnable, day);

      dayCol.appendChild(timeNode);
    });

    return dayNode;
  };

  const createSlideNode = (i) => {
    const slideNode = slideTemplate.content.cloneNode(true);
    const days = dateArray.slice(i * 5, (i + 1) * 5);
    const daysBlock = slideNode.querySelector('.registration-calendar__days');
    const monthBlock = slideNode.querySelector('.registration-calendar__month');

    // add month-name to slide
    const monthOfFirstDay = moment(days[0]).month();
    const monthOfLastDay = moment(days[days.length - 1]).month();

    if (monthOfLastDay === monthOfFirstDay) {
      monthBlock.textContent = capitalize(moment.months(monthOfFirstDay));
    } else {
      monthBlock.textContent = `${capitalize(moment.months(monthOfFirstDay))} - ${capitalize(moment.months(monthOfLastDay))}`;
    }

    // each day in slide
    days.forEach((day) => {
      const dayNode = createDayNode(day);

      daysBlock.appendChild(dayNode);
    });

    return slideNode;
  };

  for (let i = 0; i < slidesNumber; i++) {
    // each 5 day create slide
    const slideNode = createSlideNode(i);

    sliderWrapper.appendChild(slideNode);
  }

  const registrationCalendarSlider = document.querySelector('.registration-calendar');
  const prevBtn = registrationCalendarSlider.querySelector('.registration-calendar__nav');
  const nextBtn = registrationCalendarSlider.querySelector('.registration-calendar__nav--next');

  const slider = new Swiper(registrationCalendarSlider, {
    slidesPerView: 'auto',
    allowTouchMove: false,
    navigation: {
      prevEl: prevBtn,
      nextEl: nextBtn,
    },
  });

  const vkWidth = document.querySelector('#vk-groups').clientWidth;
  VK.Widgets.Group('vk-groups', {
    mode: 3,
    height: 400,
    width: vkWidth,
    color1: 'FFFFFF',
    color2: '000000',
    color3: '5181B8',
  }, 14532206);
};

export {initRegistration};
