const buttons = document.querySelectorAll('[data-favorite]');

const initAddFavorite = () => {
  if (!buttons) {
    return;
  }

  const cards = document.querySelectorAll('.product-card');

  cards.forEach((card) => {
    card.addEventListener('click', (e) => {
      if (e.target.closest('[data-favorite]')) {
        e.preventDefault();
        e.target.closest('[data-favorite]').classList.toggle('favorite');
      }
    });
  });
};

export {initAddFavorite};
