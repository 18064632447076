const product = document.querySelector('.product');
const breakpoint = window.matchMedia('(max-width: 768px)');
const sliderPagination = document.querySelector('.product-slider__pagination');

const oneSliderCenter = () => {
  if (!swiperSlides) {
    return;
  }

  const swiperSlides = product.querySelectorAll('.swiper-slide');
  for (let i = 0; i < swiperSlides.length; i++) {
    // eslint-disable-next-line eqeqeq
    if (swiperSlides.length === 1 && (breakpoint.matches)) {
      swiperSlides[0].classList.add('center-slide');
      sliderPagination.style.display = 'none';
    }
  }
};

export {oneSliderCenter};
