let frontBackCards = document.querySelectorAll('[data-card-back]');
let backButton = document.querySelector('.catalog-action__button-round');
let click = false;

const backImgHover = () => {
  document.addEventListener('mousemove', (e) => {

    if (e.target.closest('[data-card-back]')) {
      const card = e.target.closest('[data-card-back]');

      const pics = card.querySelectorAll('.product-card__img');
      const buttons = card.querySelectorAll('.img-pagination__btn');
      if (e.target === buttons[1]) {
        let hover = true;
        setTimeout(() => {
          if (hover) {
            pics[1].style.opacity = 1;
            buttons[1].addEventListener('mouseleave', () => {
              pics[1].style.opacity = 0;
            });
          }
        }, 200);
        buttons[1].addEventListener('mouseleave', () => {
          hover = false;
        }, {once: true});
      }
    }
  });
};

const backAround = () => {
  document.addEventListener('click', (e) => {

    if (e.target.closest('.catalog-action__button-round')) {
      backButton = document.querySelector('.catalog-action__button-round');
      const btnText = backButton.getAttribute('data-text');
      const reverseText = backButton.getAttribute('data-text-reverse');
      frontBackCards = document.querySelectorAll('[data-card-back]');
      if (!click) {
        click = true;
        frontBackCards.forEach((card) => {
          const pics = card.querySelectorAll('.product-card__img');
          const buttons = card.querySelectorAll('.img-pagination__btn');

          backButton.classList.add('is-active');
          backButton.querySelector('span').innerText = reverseText;
          buttons.forEach((button) => {
            button.style.pointerEvents = 'none';
          });
          pics[1].style.opacity = 1;
          card.addEventListener('mouseleave', () => {
            pics[1].style.opacity = 1;
          });
        });
      } else {
        backButton.classList.remove('is-active');
        backButton.querySelector('span').innerText = btnText;
        frontBackCards.forEach((card) => {

          const pics = card.querySelectorAll('.product-card__img');
          const buttons = card.querySelectorAll('.img-pagination__btn');

          pics[1].style.opacity = 0;
          buttons.forEach((button) => {
            button.style.pointerEvents = null;
          });
          click = false;

          card.addEventListener('mouseleave', () => {
            pics[1].style.opacity = 0;
          });
        });
      }
    }

  });
};

const initBackImg = () => {

  if (frontBackCards.length > 0) {
    backImgHover();
  }

  if (frontBackCards.length > 0 && backButton) {
    backAround();
  }
};

export {initBackImg};
