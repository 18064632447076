const maps = document.querySelectorAll('.map-block');

const initMapReady = (map) => {
  const url = map.dataset.url;

  function handler(entries) {
    for (let entry of entries) {
      if (entry.isIntersecting) {
        if (!map.classList.contains('is-initialized')) {
          init();
        }
      }
    }
  }

  const observer = new IntersectionObserver(handler, {threshold: 0.1});
  observer.observe(map);

  function init() {
    map.classList.add('is-initialized');
    const elem = document.createElement('script');
    elem.type = 'text/javascript';
    elem.src = `${url}&onload=window.getYaMap`;
    document.getElementsByTagName('body')[0].appendChild(elem);
  }

  window.getYaMap = function () {
    let ymaps = window.ymaps;

    if (!ymaps) {
      return;
    }

    const myMap = new ymaps.Map(map, {
      center: JSON.parse(map.dataset.coords),
      zoom: map.dataset.zoom,
      controls: [],
      behaviors: ['multiTouch', 'scrollZoom'],
    });

    if ((navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i))) {
      myMap.behaviors.disable('scrollZoom');
      myMap.behaviors.disable('drag');
    } else {
      myMap.behaviors.enable('drag');
    }

    const placemarkLayout = ymaps.templateLayoutFactory.createClass(
        `<div class="placemark">
          <img src="img/svg/pin.svg" width="46" height="63" alt="baloon">
          <div class="placemark-text">
            <div class="placemark-title">$[properties.placemarkHeader]</div>
          </div>
        </div>`
    );

    ymaps.findOrganization('90732901201')
        .then(
            function (orgGeoObject) {
              orgGeoObject.properties.set({
                placemarkHeader: 'Дворянка',
              });

              orgGeoObject.options.set({
                iconLayout: placemarkLayout,
                zIndex: 1,
                interactiveZIndex: true,
                // Описываем фигуру активной области "Круг".
                iconShape: {
                  type: 'Circle',
                  // Круг описывается в виде центра и радиуса
                  coordinates: [0, -30],
                  radius: 30,
                },
              });

              myMap.geoObjects.add(orgGeoObject);

              myMap.geoObjects.add('click', function () {
                orgGeoObject.balloon.open();
              });
            }
        );
  };
};

const initMap = () => {
  if (maps.length < 1) {
    return;
  }

  maps.forEach((map) => {
    initMapReady(map);
  });
};

export {initMap};
