const initStickyBtn = () => {
  const breakpoint = window.matchMedia('(max-width: 768px)');
  const stickyBtn = document.querySelector('.sticky-btn__btn');
  const header = document.querySelector('.header');
  let headerHeight = 0;
  let scrollDistance = window.scrollY;

  if (!stickyBtn) {
    return;
  }

  const showStickyBtn = () => {
    headerHeight = header.offsetHeight;

    if ((breakpoint.matches) || (scrollDistance > headerHeight) && (!header.classList.contains('header--fixed'))) {
      stickyBtn.classList.add('show');
    } else {
      stickyBtn.classList.remove('show');
    }

    scrollDistance = window.scrollY;
  };

  window.addEventListener('scroll', showStickyBtn);
  showStickyBtn();
};

export {initStickyBtn};
