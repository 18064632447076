import {A11Y} from '../../constants';

const productSlider = document.querySelector('.product-slider');
const slides = document.querySelectorAll('.product-slider__item');

const productSliderSetup = () => {
  // eslint-disable-next-line no-new
  new window.Swiper(productSlider, {
    navigation: {
      prevEl: productSlider.querySelector('.product-slider__btn--prev'),
      nextEl: productSlider.querySelector('.product-slider__btn--next'),
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
    },
    a11y: A11Y,
    keyboard: {
      enabled: true,
    },
    centeredSlidesBounds: true,
    slidesPerView: 'auto',
    loop: false,
    watchSlidesVisibility: true,
    watchOverflow: true,
    slideVisibleClass: '.swiper-slide-visible',
    resistance: true,
    resistanceRatio: 0,
    breakpoints: {
      320: {
        slidesPerView: 'auto',
      },
      1024: {
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
      1440: {
        slidesOffsetBefore: 170,
        slidesOffsetAfter: -170,
      },
    },
  });
};


const initProductSlider = () => {
  if (!productSlider) {
    return;
  }

  setTimeout(() => {
    productSliderSetup();
    productSlider.style.opacity = 1;
  }, 1000);
};

export {initProductSlider};
