export const openMenu = () => {
  if (document.querySelector('.main-nav')) {
    const header = document.querySelector('.header');
    const toggle = header.querySelector('.header__toggle');
    const textOpen = toggle.getAttribute('data-text-open');
    const textClose = toggle.getAttribute('data-text-close');
    const mainNav = document.querySelector('.main-nav');
    const mainNavAppearance = document.querySelector('.main-nav--appearance');
    let headerHeight = 0;

    header.classList.remove('header--nojs');


    window.addEventListener('load', function () {
      mainNav.style.visibility = 'visible';
    });

    const showMenu = () => {
      header.classList.add('header--open');
      toggle.classList.add('header__toggle--open');
    };

    const hideMenu = () => {
      header.classList.remove('header--open');
      toggle.classList.remove('header__toggle--open');
    };

    toggle.addEventListener('click', () => {

      headerHeight = header.offsetHeight;

      if (header.classList.contains('header--open')) {
        hideMenu();
        toggle.setAttribute('aria-label', textOpen);
        toggle.setAttribute('aria-pressed', false);
        window.scrollLock.enableScrolling();

      } else {
        mainNav.style.height = `${document.documentElement.clientHeight - headerHeight}px`;
        mainNavAppearance.style.top = `${headerHeight}px`;
        showMenu();
        toggle.setAttribute('aria-label', textClose);
        toggle.setAttribute('aria-pressed', true);
        window.scrollLock.disableScrolling();
      }

      if (header.classList.contains('header--no-effect')) {
        header.classList.remove('header--no-effect');
      }
    });

    window.addEventListener('resize', (evt) => {
      headerHeight = header.offsetHeight;

      if (evt.currentTarget.innerWidth > 1023) {
        mainNav.style.height = null;
        mainNavAppearance.style.top = null;
      }

      if (evt.currentTarget.innerWidth < 1023 && header.classList.contains('header--open')) {
        mainNavAppearance.style.top = `${headerHeight}px`;
      }

      if (evt.currentTarget.innerWidth > 1023 && header.classList.contains('header--open')) {
        hideMenu();
        window.scrollLock.enableScrolling();
      }

      if (evt.currentTarget.innerWidth < 1024 && !header.classList.contains('header--no-effect')) {
        header.classList.add('header--no-effect');

      }
    });
  }
};
