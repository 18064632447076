import {A11Y} from '../../constants';

const cardSlider = document.querySelector('.slider-cards');
const breakpoint = window.matchMedia('(max-width:767px)');

const breakpointChecker = () => {
  if (breakpoint.matches) {
    let currentSwiper = new window.Swiper(cardSlider, {
      slidesPerView: 'auto',
      spaceBetween: 2,
      slidesOffsetAfter: 20,
      slidesOffsetBefore: 20,
      // watchOverflow: true,
      allowTouchMove: true,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
      },
      a11y: A11Y,
      keyboard: {
        enabled: true,
      },
    });
  } else {
    if (cardSlider.swiper) {
      cardSlider.swiper.destroy();
    }
  }
};

const initCardSlider = () => {
  if (!cardSlider) {
    return;
  }

  breakpoint.addListener(breakpointChecker);
  breakpointChecker();
};

export {initCardSlider};
