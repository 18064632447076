const btnPlay = document.querySelector('.video__btn');

const initVideoPlay = () => {

  if (!btnPlay) {
    return;
  }

  const video = document.querySelector('.video__content');

  function togglePlay() {
    if (video.paused) {
      video.controls = true;
      video.play();
      btnPlay.classList.add('hide');

    } else {
      video.controls = false;
      video.pause();
      btnPlay.classList.remove('hide');
    }
  }

  btnPlay.addEventListener('click', togglePlay);
  video.addEventListener('click', togglePlay);
};

export {initVideoPlay};
