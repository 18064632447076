const initFixedHeader = () => {
  const breakpoint = window.matchMedia('(min-width: 1440px)');
  const header = document.querySelector('.header');
  const main = document.querySelector('main');
  const stickyBlock = document.querySelector('[data-watch-header]');

  let headerHeight = 0;
  let lastScrollTop = 0;
  let scrollDistance = window.scrollY;

  if (header === null) {
    return;
  }

  setTimeout(() => {
    header.removeAttribute('data-fix-block');
  });

  const onScroll = () => {
    headerHeight = header.offsetHeight;
    if (!header.classList.contains('header--open')) {
      if (scrollDistance > lastScrollTop || window.scrollY <= 0) {
        main.style.marginTop = '0';
        header.classList.remove('header--fixed');
        if (stickyBlock) {
          stickyBlock.style.top = null;
        }
      } else {
        if (window.scrollY > headerHeight) {
          header.classList.add('header--fixed');
          header.setAttribute('data-fix-block', '');
          main.style.marginTop = `${headerHeight}px`;
          if (stickyBlock) {
            stickyBlock.style.top = null;

            if (breakpoint.matches) {
              stickyBlock.style.top = `${headerHeight}px`;
            }
          }
        }
      }
    }
    lastScrollTop = scrollDistance;
    scrollDistance = window.scrollY;
  };

  window.addEventListener('scroll', onScroll);

  window.addEventListener('resize', () => {
    scrollDistance = lastScrollTop;
    if (window.scrollY > headerHeight) {
      headerHeight = header.offsetHeight;
      main.style.marginTop = `${headerHeight}px`;
    }
  });
};

export {initFixedHeader};
