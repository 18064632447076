export const openFilters = () => {
  if (document.querySelector('.filters')) {
    const btnCloseFilter = document.querySelector('[data-close-filters]');
    const btnOpenFilter = document.querySelector('[data-open-filters]');
    const filters = document.querySelector('.filters');

    const showFilters = () => {
      filters.classList.add('filters--open');
    };

    const hideFilters = () => {
      filters.classList.remove('filters--open');
    };

    btnCloseFilter.addEventListener('click', () => {
      if (filters.classList.contains('filters--open')) {
        hideFilters();
        window.scrollLock.enableScrolling();
      }
    });

    btnOpenFilter.addEventListener('click', () => {
      showFilters();
      window.scrollLock.disableScrolling();
    });

    window.addEventListener('keydown', function (evt) {
      const isEscKey = evt.key === 'Escape' || evt.key === 'Esc';
      if (isEscKey) {
        evt.preventDefault();
        if (filters.classList.contains('filters--open')) {
          hideFilters();
          window.scrollLock.enableScrolling();
        }
      }
    });

    document.addEventListener('click', (evt) => {
      const target = evt.target;

      if (target.classList.contains('filters__overlay')) {
        hideFilters();
        window.scrollLock.enableScrolling();
      }
    });

    const breakpoint = window.matchMedia('(min-width:1280px)');
    const breakpointChecker = () => {
      if (breakpoint.matches) {
        if (filters.classList.contains('filters--open')) {
          hideFilters();
          window.scrollLock.enableScrolling();
        }
      }
    };
    breakpoint.addListener(breakpointChecker);
    breakpointChecker();
  }
};
