const button = document.querySelector('.btn--product');

const btnTextReplace = () => {
  if (!button) {
    return;
  }
  const breakpoint = window.matchMedia('(max-width: 768px)');

  button.addEventListener('click', () => {
    if (!breakpoint.matches) {
      button.classList.toggle('is-favorite');
    } else {
      button.classList.toggle('add-favorite');
    }
  });
};

export {btnTextReplace};
