import {iosVhFix} from './utils/ios-vh-fix';
import {dynamicAdaptive} from './utils/dimanic-adaptive';
import {initModals} from './modules/modals/init-modals';
import {initCustomSelect} from './modules/form/init-custom-select';
import {initFormValidate} from './modules/form/init-form-validate';
import {openMenu} from './modules/menu';
import {initMap} from './modules/init-map';
import {initAddFavorite} from './modules/init-add-favorite';
import {initFixedHeader} from './modules/init-fix-header';
import {initProductSlider} from './modules/sliders/init-product-slider';
import {initCardSlider} from './modules/sliders/init-card-slider';
import {initRegistration} from './modules/init-registration';
import {initAccordions} from './modules/init-accordion';
import {initVideoPlay} from './modules/init-video';
import {initStickyBtn} from './modules/init-sticky-btn';
import {oneSliderCenter} from './modules/sliders/one-slider-center';
import {initAos} from './modules/init-aos';
import {openFilters} from './modules/filters';
import {initBackImg} from './modules/init-back-img';
import {btnTextReplace} from './modules/btn-text-replace';
// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {
  openMenu();
  // Utils
  // ---------------------------------

  iosVhFix();
  dynamicAdaptive();

  // Modules
  // ---------------------------------

  initAddFavorite();
  initFixedHeader();
  initAccordions();
  initStickyBtn();
  oneSliderCenter();
  initAos();

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    initMap();
    initModals();
    initCustomSelect();
    initFormValidate();
    initProductSlider();
    initCardSlider();
    initRegistration();
    initVideoPlay();
    openFilters();
    initBackImg();
    btnTextReplace();
  });
});

// ---------------------------------

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✅

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
